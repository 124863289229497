import { Typography } from "@mui/material";
import React from "react";
import NavBar from "../components/navbar";


const ContactUs: React.FC = () => {
    return (
        <>
            <div>
                <NavBar />
            </div>
        </>
    )
}

export default ContactUs;